<template>
    <div>
        <vs-table
            search 
            stripe 
            border 
            description 
            :sst="true" 
            :data="table.data" 
            :max-items="table.length"
            :total="table.total" 
            @search="handleSearch" 
            @change-page="handleChangePage" 
            @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>

            <template v-if="status === 'Receive'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Sales</vs-th>
                <vs-th sort-key="">Item Request Doc.</vs-th>
                <vs-th sort-key="">Loading Date</vs-th>
                <vs-th sort-key="">Unloading Doc.</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Load Qty (UOM)</vs-th>
                <vs-th sort-key="">Total Sales Qty (UOM)</vs-th>
                <vs-th sort-key="">Total Unload Qty (UOM)</vs-th>
            </template>

            <template v-if="status === 'Check'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Sales</vs-th>
                <vs-th sort-key="">Item Request Doc.</vs-th>
                <vs-th sort-key="">Loading Date</vs-th>
                <vs-th sort-key="">Unloading Doc.</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Load Qty (UOM)</vs-th>
                <vs-th sort-key="">Total Sales Qty (UOM)</vs-th>
                <vs-th sort-key="">Total Unload Qty (UOM)</vs-th>
                <vs-th sort-key="">Delta Checker (UOM)</vs-th>
            </template>

            <!-- <template v-if="status === 'Putaway'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Sales</vs-th>
                <vs-th sort-key="">Item Request Doc.</vs-th>
                <vs-th sort-key="">Loading Date</vs-th>
                <vs-th sort-key="">Unloading Doc.</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Load Qty (UOM)</vs-th>
                <vs-th sort-key="">Total Sales Qty (UOM)</vs-th>
                <vs-th sort-key="">Total Unload Qty (UOM)</vs-th>
            </template> -->

            <template v-if="status === 'Complete'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Sales</vs-th>
                <vs-th sort-key="">Item Request Doc.</vs-th>
                <vs-th sort-key="">Loading Date</vs-th>
                <vs-th sort-key="">Unloading Doc.</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Load Qty (UOM)</vs-th>
                <vs-th sort-key="">Total Sales Qty (UOM)</vs-th>
                <vs-th sort-key="">Total Unload Qty (UOM)</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td v-if="status === 'Receive'">
                        <div class="vx-input-group flex">
                            <vs-button 
                                size="small" 
                                color="warning" 
                                icon-pack="feather" 
                                icon="icon-edit" 
                                title="Edit"
                                style="margin-right: 5px" 
                                @click="handleEdit(tr.id)"
                            />
                            <vs-button 
                                size="small" 
                                color="success" 
                                icon-pack="feather" 
                                icon="icon-check" 
                                title="Confirm"
                                @click="handleConfirm(tr.id)"
                                :disabled="tr.delta_checker == null"
                            />
                        </div>
                    </vs-td>
                    <vs-td v-if="status === 'Receive'">{{ tr.sales_name }}</vs-td>
                    <vs-td v-if="status === 'Receive'">{{ tr.request_code }}</vs-td>
                    <vs-td v-if="status === 'Receive'">{{ tr.loading_date }}</vs-td>
                    <vs-td v-if="status === 'Receive'">{{ tr.unloading_doc }}</vs-td>
                    <vs-td v-if="status === 'Receive'">{{ tr.total_sku }}</vs-td>
                    <vs-td v-if="status === 'Receive'">{{ tr.total_load_qty }}</vs-td>
                    <vs-td v-if="status === 'Receive'">{{ tr.total_sales_qty }}</vs-td>
                    <vs-td v-if="status === 'Receive'">{{ tr.total_unload_qty }}</vs-td>

                    <vs-td v-if="status === 'Check'">
                        <div class="vx-input-group flex">
                            <vs-button 
                                size="small" 
                                color="warning" 
                                icon-pack="feather" 
                                icon="icon-edit" 
                                title="Edit"
                                style="margin-right: 5px" 
                                @click="handleEditCheck(tr.id)"
                            />
                            <vs-button 
                                size="small" 
                                color="success" 
                                icon-pack="feather" 
                                icon="icon-check" 
                                title="Confirm"
                                @click="handleConfirmCheck(tr.id)"
                                :disabled="tr.putaway_checker == null"
                            />
                        </div>
                    </vs-td>
                    <vs-td v-if="status === 'Check'">{{ tr.sales_name }}</vs-td>
                    <vs-td v-if="status === 'Check'">{{ tr.request_code }}</vs-td>
                    <vs-td v-if="status === 'Check'">{{ tr.loading_date }}</vs-td>
                    <vs-td v-if="status === 'Check'">{{ tr.unloading_doc }}</vs-td>
                    <vs-td v-if="status === 'Check'">{{ tr.total_sku }}</vs-td>
                    <vs-td v-if="status === 'Check'">{{ tr.total_load_qty }}</vs-td>
                    <vs-td v-if="status === 'Check'">{{ tr.total_sales_qty }}</vs-td>
                    <vs-td v-if="status === 'Check'">{{ tr.total_unload_qty }}</vs-td>
                    <vs-td v-if="status === 'Check'">{{ (tr.putaway_checker == null) ? 'Empty putaway' : tr.putaway_checker }}</vs-td>

                    <!-- <vs-td v-if="status === 'Putaway'">
                        <div class="vx-input-group flex">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit" title="Edit"
                                style="margin-right: 5px" @click="handleEditPutaway(tr.id)"/>
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check" title="Confirm"
                                @click="handleConfirmPutaway(tr.id)"/>
                        </div>
                    </vs-td>
                    <vs-td v-if="status === 'Putaway'">{{ tr.sales_name }}</vs-td>
                    <vs-td v-if="status === 'Putaway'">{{ tr.request_code }}</vs-td>
                    <vs-td v-if="status === 'Putaway'">{{ tr.loading_date }}</vs-td>
                    <vs-td v-if="status === 'Putaway'">{{ tr.unloading_doc }}</vs-td>
                    <vs-td v-if="status === 'Putaway'">{{ tr.total_sku }}</vs-td>
                    <vs-td v-if="status === 'Putaway'">{{ tr.total_load_qty }}</vs-td>
                    <vs-td v-if="status === 'Putaway'">{{ tr.total_sales_qty }}</vs-td>
                    <vs-td v-if="status === 'Putaway'">{{ tr.total_unload_qty }}</vs-td> -->

                    <vs-td v-if="status === 'Complete'">
                        <div class="vx-input-group flex">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye" title="Detail"
                                style="margin-right: 5px" @click="handleDetailComplete(tr.id)"/>
                        </div>
                    </vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.sales_name }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.request_code }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.loading_date }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.unloading_doc }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.total_sku }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.total_load_qty }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.total_sales_qty }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.total_unload_qty }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage"/>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        territoryId: {
            type: Array,
        },
        warehouseId: {
            type: Array,
        },
        salesId: {
            type: Array,
        },
        status: {
            type: String,
            default: "Receive",
        },
    },
    data() {
        return {
            deleteId: null,
            action: null,
            table: this.tableDefaultState(),
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        getData() {
            let territoryIds = [];
            this.territoryId.forEach(val => {
                territoryIds.push(val.id)
            });

            let warehouseIds = [];
            this.warehouseId.forEach(val => {
                warehouseIds.push(val.id)
            });

            let salesIds = [];
            this.salesId.forEach(val => {
                salesIds.push(val.id)
            });

            let param = {
                length: this.table.length,
                page: this.table.page,
                search: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                territory_id: territoryIds,
                warehouse_id: warehouseIds,
                personal_id: salesIds,
                status: this.status,
            }

            this.$vs.loading();
            this.$http.post("/api/wms/v1/canvas/unloading/data-table", param)
            .then((resp) => {
                if (resp.code == 200) {
                    this.table.total = resp.data.total_record;
                    this.table.totalPage = resp.data.total_page;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.total_record_per_page;
                    this.table.data = resp.data.records;
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
                this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: error,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleEdit(id) {
            this.$router.push({
                name: "canvas.unloading-edit-receive",
                params: { id: id },
            });
        },
        handleConfirm(id) {
            this.deleteId = id
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to confirm this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/canvas/unloading/confirm-receive/" + this.deleteId)
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: "The data was successfully confirmed",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        }

                        this.$vs.loading.close();
                        this.getData();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: error,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    });
                }
            })
        },
        handleEditCheck(id) {
            this.$router.push({
                name: "canvas.unloading-edit-check",
                params: { id: id },
            });
        },
        handleConfirmCheck(id){
            this.deleteId = id
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to confirm this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/canvas/unloading/confirm-putaway/" + this.deleteId)
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: "The data was successfully confirmed",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        }

                        this.$vs.loading.close();
                        this.getData();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: error,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    });
                }
            })
        },
        handleEditPutaway(id){
            this.$router.push({
                name: "canvas.unloading-edit-putaway",
                params: { id: id },
            });
        },
        handleConfirmPutaway(id){
            this.deleteId = id
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to confirm this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/canvas/unloading/complete-putaway/" + this.deleteId)
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: "The data was successfully completed",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        }

                        this.$vs.loading.close();
                        this.getData();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: error,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    });
                }
            })
        },
        handleDetailComplete(id){
            this.$router.push({
                name: "canvas.unloading-edit-complete",
                params: {id: id},
            })
        }
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
}
</script>